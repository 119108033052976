import { IModalDialog } from '@models/components/modal.model';
import dynamic from 'next/dynamic';
const Button = dynamic(() => import('@components/ui-elements/button'));

interface IModalDialogAction {
  modal?: IModalDialog;
  setOpen: any;
}

const ModalDialogAction = ({ modal, setOpen }: IModalDialogAction) => {
  if (!modal?.isConfirm && !modal?.isClose) return null;

  return (
    <>
      <div className="flex items-center space-x-4 mt-4">
        {modal?.isConfirm && (
          <Button
            type="button"
            color="primary"
            size="small"
            onClick={() => {
              modal.onConfirm();
              setOpen(false);
            }}
            isBlock>
            <span className="font-semibold">{modal?.textConfirm}</span>
          </Button>
        )}
        {modal?.isClose && (
          <Button type="button" color="gray" size="small" onClick={() => setOpen(false)} isBlock>
            {modal?.textClose ? modal.textClose : 'Cancel'}
          </Button>
        )}
      </div>
    </>
  );
};

export default ModalDialogAction;
